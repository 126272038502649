<script lang="ts" setup>
import type { User } from '../types/user'
import { router } from '@inertiajs/vue3'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  edit_user_registration_path,
  groups_path,
  oauth_applications_path,
  profile_path,
  profiles_path,
} from '../routes'

const { profileId, user } = defineProps<{
  user: User | null
  profileId: string
}>()

const emit = defineEmits<{
  (e: 'closeDrawer'): void
}>()

const i18n = useI18n()

const menuItems = computed(() => {
  const items = [
    {
      icon: 'home',
      text: i18n.t('drawer.home'),
      href: '/',
    },
    {
      icon: 'user',
      text: i18n.t('drawer.profile'),
      href: profile_path(profileId),
    },
    {
      icon: 'lock-on',
      text: i18n.t('drawer.account'),
      href: edit_user_registration_path(),
    },
  ]

  if (user?.is_global_admin) {
    items.push(
      {
        icon: 'app',
        text: i18n.t('applications.title'),
        href: oauth_applications_path(),
      },
      {
        icon: 'user-list',
        text: i18n.t('users.title'),
        href: profiles_path(),
      },
      {
        icon: 'usergroup',
        text: i18n.t('groups.title'),
        href: groups_path(),
      },
    )
  }

  return items
})

const route = ref(globalThis.location.pathname)

router.on('start', (event) => {
  route.value = event.detail.visit.url.pathname
})

function onMenuItemClick(href: string) {
  if (href === route.value) {
    emit('closeDrawer')
    return
  }

  router.visit(href)
}
</script>

<template>
  <TMenu style="width: 100%" :value="route">
    <TMenuItem
      v-for="menuItem in menuItems"
      :key="menuItem.href"
      :value="menuItem.href"
      @click="onMenuItemClick(menuItem.href)"
    >
      <template #icon>
        <TIcon :name="menuItem.icon" />
      </template>
      {{ menuItem.text }}
    </TMenuItem>
  </TMenu>
</template>
