<script lang="ts" setup>
import type { Application } from '../../types/application'
import { router } from '@inertiajs/vue3'
import { useClipboard } from '@vueuse/core'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { edit_oauth_application_path, oauth_application_path, oauth_applications_path, oauth_authorization_path } from '../../routes'

const { application } = defineProps<{
  application: Application
}>()

const i18n = useI18n()

const { copy: copyUid, copied: copiedUid } = useClipboard()
const { copy: copySecret, copied: copiedSecret } = useClipboard()

function editApplication() {
  router.visit(edit_oauth_application_path(application.id))
}

function deleteApplication() {
  router.delete(oauth_application_path(application.id))
}

function goBack() {
  router.visit(oauth_applications_path())
}

function authorization_path(redirect_uri: string) {
  return oauth_authorization_path({
    client_id: application.uid,
    redirect_uri,
    response_type: 'code',
    scope: application.scopes.join(' '),
  })
}

const showDeleteDialog = ref(false)
</script>

<template>
  <TCard class="m-4" header-bordered :title="i18n.t('application.title', [application.name])">
    <template #actions>
      <TButton theme="secondary" shape="circle" variant="text" @click="goBack">
        <TIcon name="rollback" />
      </TButton>
      <TButton theme="primary" class="ml-2" shape="circle" variant="text" @click="editApplication">
        <TIcon name="edit" />
      </TButton>
      <TButton theme="danger" class="ml-2" shape="circle" variant="text" @click="showDeleteDialog = true">
        <TIcon name="delete" />
      </TButton>
    </template>
    <div class="font-bold">
      {{ i18n.t('application.uid') }}
    </div>
    <div>
      <code>{{ application.uid }}</code>
      <TButton variant="outline" class="ml-2" @click="copyUid(application.uid)">
        <TIcon v-if="!copiedUid" name="copy" />
        <TIcon v-else name="check" />
      </TButton>
    </div>
    <div class="font-bold mt-4">
      {{ i18n.t('application.secret') }}
    </div>
    <div>
      <code>{{ application.secret }}</code>
      <TButton variant="outline" class="ml-2" @click="copySecret(application.secret)">
        <TIcon v-if="!copiedSecret" name="copy" />
        <TIcon v-else name="check" />
      </TButton>
    </div>
    <div class="font-bold mt-4">
      {{ i18n.t('application.scopes') }}
    </div>
    <div>
      <ul>
        <li v-for="scope in application.scopes" :key="scope">
          <code>{{ scope }}</code>: {{ i18n.t(`scopes.${scope}`) }}
        </li>
      </ul>
    </div>
    <div class="font-bold mt-4">
      {{ i18n.t('application.callback-urls') }}
    </div>
    <div>
      <ul>
        <li v-for="redirect_uri in application.redirect_uri.split('\n')" :key="redirect_uri">
          <code>{{ redirect_uri.trim() }}</code>
          <TButton size="small" class="ml-2" variant="outline" :href="authorization_path(redirect_uri)" target="_blank">
            Authorize
          </TButton>
        </li>
      </ul>
    </div>
    <div class="font-bold mt-4">
      {{ i18n.t('application.confidential') }}
    </div>
    <div>
      <code>{{ application.confidential ? 'Yes' : 'No' }}</code>
    </div>
  </TCard>
  <TDialog v-model:visible="showDeleteDialog" :header="i18n.t('applications.delete')" @confirm="deleteApplication">
    <p>{{ i18n.t('applications.delete-dialog') }}</p>
  </TDialog>
</template>
