<script lang="ts" setup>
import type { Profile } from '../../types/user'
import { router } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
import defaultAvatar from '../../assets/images/default-avatar.png'
import { edit_profile_path } from '../../routes'

const { profile } = defineProps<{
  profile: Profile
}>()

const i18n = useI18n()

function editProfile() {
  router.visit(edit_profile_path(profile.id))
}
</script>

<template>
  <TCard style="margin: 1rem" :title="i18n.t('profile.title')">
    <template #actions>
      <TButton @click="editProfile">
        {{ i18n.t('profile.edit') }}
      </TButton>
    </template>
    <div class="flex">
      <TImage :src="profile.avatar ?? defaultAvatar" style="width: 110px; height: 110px; object-fit: cover;" />
      <div class="ml-4 flex-1">
        <div class="text-2xl font-bold">
          {{ profile.display_name }}
        </div>
        <div>
          {{ profile.email }}
        </div>
        <div>
          <TIcon name="cake" />
          {{ profile.birthday }}
        </div>
        <div>{{ profile.preferred_language }}</div>
      </div>
    </div>
  </TCard>
</template>
