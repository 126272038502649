<script lang="ts" setup>
import { useForm } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
import FlashMessages from '../../../components/FlashMessages.vue'
import { user_password_path } from '../../../routes'

defineProps<{
  flash: Record<string, string[]>
}>()

const i18n = useI18n()

const form = useForm({
  password: '',
  password_confirmation: '',
})

function updatePassword() {
  const token = new URLSearchParams(window.location.search).get('reset_password_token')
  form.transform(data => ({ user: { ...data, reset_password_token: token } })).put(user_password_path())
}
</script>

<template>
  <div class="flex justify-center items-center flex-col h-100%">
    <FlashMessages :flash="flash" class="w-120" />

    <TCard :title="i18n.t('passwords.edit.title')" class="w-120">
      <TForm label-width="160px" @submit="updatePassword">
        <TFormItem label="Password" :error="form.errors.password">
          <TInput v-model="form.password" type="password" />
        </TFormItem>
        <TFormItem label="Password Confirmation" :error="form.errors.password_confirmation">
          <TInput v-model="form.password_confirmation" type="password" />
        </TFormItem>
        <div class="flex justify-center">
          <TButton type="submit">
            {{ i18n.t('passwords.edit.button-text') }}
          </TButton>
        </div>
      </TForm>
    </TCard>
  </div>
</template>
