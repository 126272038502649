<script lang="ts" setup>
import type { User } from '../types/user'
import { Link, router } from '@inertiajs/vue3'
import enConfig from 'tdesign-vue-next/es/locale/en_US'
import cnConfig from 'tdesign-vue-next/es/locale/zh_CN'

import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Logo from '../assets/images/Logo.vue'
import DrawerMenu from '../components/DrawerMenu.vue'
import { destroy_user_session_path, locale_index_path } from '../routes'
import { useThemeStore } from '../store/theme'

const { current_user, current_profile_id, current_locale } = defineProps<{
  // eslint-disable-next-line vue/prop-name-casing
  current_user: User | null
  // eslint-disable-next-line vue/prop-name-casing
  current_profile_id: string | null
  // eslint-disable-next-line vue/prop-name-casing
  current_locale: string
}>()

const i18n = useI18n()
const themeStore = useThemeStore()
const drawerVisible = ref(false)

function signOut() {
  router.delete(destroy_user_session_path())
}

const showLocaleSelector = ref(false)
const tDesignConfigMap: Record<string, any> = {
  'en': enConfig,
  'zh-CN': cnConfig,
}

const tDesignConfig = ref(cnConfig)

function setLocale(locale: string) {
  router.patch(locale_index_path(), { locale })
}

function toggleTheme(currentTheme: 'light' | 'dark') {
  themeStore.theme = currentTheme

  if (!document.startViewTransition) {
    document.documentElement.removeAttribute('theme-mode')
    document.documentElement.setAttribute('theme-mode', currentTheme)
    return
  }

  document.startViewTransition(() => {
    document.documentElement.removeAttribute('theme-mode')
    document.documentElement.setAttribute('theme-mode', currentTheme)
  })
}

watch(() => current_locale, (l) => {
  i18n.locale.value = l
  tDesignConfig.value = tDesignConfigMap[l]
})

onMounted(() => {
  i18n.locale.value = current_locale
  tDesignConfig.value = tDesignConfigMap[current_locale]

  document.documentElement.setAttribute('theme-mode', themeStore.theme)
})
</script>

<template>
  <TConfigProvider :global-config="tDesignConfig">
    <TLayout class="h-100vh">
      <THeader>
        <div class="flex h-full items-center px-4 gap-x-4">
          <div class="flex flex-col">
            <Link href="/">
              <Logo class="fill-primary h-6" />
            </Link>
          </div>
          <div class="flex-1" />
          <TRadioGroup v-model="themeStore.theme" variant="default-filled" @change="toggleTheme">
            <TRadioButton value="light">
              <TIcon name="sunny" />
            </TRadioButton>
            <TRadioButton value="dark">
              <TIcon name="moon" />
            </TRadioButton>
          </TRadioGroup>

          <TButton v-if="!current_user" variant="text" @click="showLocaleSelector = true">
            <template #icon>
              <TIcon name="translate" />
            </template>
            {{ i18n.t(`locales.${current_locale}`) }}
          </TButton>
          <TButton v-if="current_profile_id" variant="text" @click="drawerVisible = true">
            {{ current_user?.email }}
          </TButton>
        </div>
      </THeader>
      <TContent class="flex justify-center">
        <div class="w-full max-w-1220px">
          <slot />
        </div>
      </TContent>
      <TDrawer v-if="current_profile_id" v-model:visible="drawerVisible" :header="current_user?.email">
        <DrawerMenu :user="current_user" :profile-id="current_profile_id" @close-drawer="drawerVisible = false" />
        <template #footer>
          <TButton style="width: 100%;" variant="text" @click="drawerVisible = false;signOut()">
            <template #icon>
              <TIcon name="delete-1" />
            </template>
            {{ i18n.t('drawer.sign-out') }}
          </TButton>
        </template>
      </TDrawer>
      <TFooter class="text-center">
        © {{ new Date().getFullYear() }} Yet Another AI Ltd.
      </TFooter>
    </TLayout>
    <TDialog v-model:visible="showLocaleSelector" :header="i18n.t('locales.switch-language')">
      <TButton
        v-for="locale in i18n.availableLocales"
        :key="locale" class="mr-2"
        @click="showLocaleSelector = false; setLocale(locale)"
      >
        {{ i18n.t(`locales.${locale}`) }}
      </TButton>
    </TDialog>
  </TConfigProvider>
</template>

<style scoped>
.fill-primary {
  fill: var(--td-brand-color);
}

:deep(.t-default-menu__inner .t-menu) {
  padding: 0;
}
</style>
