<script lang="ts" setup>
import type { Profile, ProfileForm } from '../../types/user'
import { useForm } from '@inertiajs/vue3'
import { useFileDialog, useObjectUrl } from '@vueuse/core'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { profile_path } from '../../routes'

const { profile } = defineProps<{
  profile: Profile
}>()

const i18n = useI18n()

const form = useForm<ProfileForm>({
  preferred_language: profile.preferred_language,
  display_name: profile.display_name,
  birthday: profile.birthday,
  avatar: null,
})

function saveProfile() {
  const data = form.data()
  if (!data.avatar) {
    delete data.avatar
  }

  form.transform(
    _ => ({ profile: data }),
  ).patch(profile_path(profile.id))
}

const fileDialog = useFileDialog({ accept: 'image/*' })
fileDialog.onChange((files) => {
  form.avatar = files?.[0] ?? null
})
const filePreview = useObjectUrl(computed(() => fileDialog.files.value?.[0] ?? null))

function cancel() {
  window.history.back()
}
</script>

<template>
  <TCard style="margin: 1rem" :title="i18n.t('profile.title')">
    <TForm label-width="160px" @submit="saveProfile">
      <TFormItem :label="i18n.t('profile.avatar')">
        <TImage v-if="profile.avatar" :src="profile.avatar" style="width: 110px; height: 110px; object-fit: cover;" />
      </TFormItem>
      <TFormItem :label="i18n.t('profile.new-avatar')">
        <div>
          <TImage v-if="form.avatar" :src="filePreview" style="width: 110px; height: 110px; object-fit: cover;" class="mb-2" />
          <TButton class="mr-2" @click="fileDialog.open">
            <template #icon>
              <TIcon name="upload" />
            </template>
            {{ form.avatar ? i18n.t('profile.change') : i18n.t('profile.select') }}
          </TButton>
          <TButton v-if="form.avatar" @click="fileDialog.reset">
            <template #icon>
              <TIcon name="delete" />
            </template>
            {{ i18n.t('profile.remove') }}
          </TButton>
        </div>
      </TFormItem>
      <TFormItem :label="i18n.t('profile.display-name')">
        <TInput v-model="form.display_name" />
      </TFormItem>
      <TFormItem :label="i18n.t('profile.birthday')">
        <TDatePicker v-model="form.birthday" />
      </TFormItem>
      <TFormItem :label="i18n.t('profile.preferred-language')">
        <TSelect v-model="form.preferred_language">
          <TOption v-for="locale in i18n.availableLocales" :key="locale" :label="i18n.t(`locales.${locale}`)" :value="locale" />
        </TSelect>
      </TFormItem>
      <TFormItem>
        <TButton type="submit">
          {{ i18n.t('profile.save') }}
        </TButton>
        <TButton class="ml-4" variant="outline" theme="danger" @click="cancel">
          {{ i18n.t('profile.cancel') }}
        </TButton>
      </TFormItem>
    </TForm>
  </TCard>
</template>
