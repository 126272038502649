<script lang="ts" setup>
const { flash } = defineProps<{
  flash: Record<string, string[] | string>
}>()

function getMsgType(msgType: string) {
  switch (msgType) {
    case 'alert':
      return 'error'
    case 'notice':
      return 'info'
    default:
      return msgType
  }
}
</script>

<template>
  <div>
    <div v-for="(messages, msgType) in flash" :key="msgType">
      <template v-if="Array.isArray(messages)">
        <TAlert v-for="message in messages" :key="message" :message="message" :theme="msgType" class="mb-4 w-full" />
      </template>
      <template v-else>
        <TAlert :message="messages" :theme="getMsgType(msgType)" class="mb-4 w-full" />
      </template>
    </div>
  </div>
</template>
