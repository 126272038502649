<script lang="ts" setup>
import type { AccountForm, User } from '../../../types/user'
import { useForm } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'

import FlashMessages from '../../../components/FlashMessages.vue'
import { user_registration_path } from '../../../routes'

const { current_user, flash } = defineProps<{
  // eslint-disable-next-line vue/prop-name-casing
  current_user: User
  flash: Record<string, string[]>
}>()

const i18n = useI18n()

const form = useForm<AccountForm>({
  email: current_user.email,
  password: null,
  password_confirmation: null,
  current_password: null,
})

function updatePassword() {
  form.transform(
    data => ({ user: data }),
  ).put(user_registration_path())
}
</script>

<template>
  <!-- TODO: Logout after password update -->
  <FlashMessages :flash="flash" class="m-4" />

  <TCard class="m-4" :title="i18n.t('account.password.title')">
    <TForm label-width="160px" @submit="updatePassword">
      <TFormItem :label="i18n.t('account.password.email')">
        <TInput v-model="form.email" />
      </TFormItem>
      <TFormItem :label="i18n.t('account.password.password')">
        <TInput v-model="form.password" type="password" />
      </TFormItem>
      <TFormItem :label="i18n.t('account.password.password-confirmation')">
        <TInput v-model="form.password_confirmation" type="password" />
      </TFormItem>
      <TFormItem :label="i18n.t('account.password.current-password')">
        <TInput v-model="form.current_password" type="password" />
      </TFormItem>
      <TFormItem>
        <TButton type="submit">
          {{ i18n.t('account.password.update') }}
        </TButton>
      </TFormItem>
    </TForm>
  </TCard>
</template>
