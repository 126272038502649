<script lang="ts" setup>
import type { Application } from '../../types/application'
import { router } from '@inertiajs/core'
import { oauth_authorization_path } from '../../routes'

const { application } = defineProps<{
  application: Application
}>()

function authorizeApplication() {
  router.visit(oauth_authorization_path({
    client_id: application.uid,
    redirect_uri: application.redirect_uri,
    response_type: 'code',
    scope: application.scopes.join(' '),
  }))
}
</script>

<template>
  <TCard :footer="application.name" class="w-50" hover-shadow cover="https://placehold.co/720x960" @click="authorizeApplication" />
</template>
