<script lang="ts" setup>
import type { Authorization } from '../../types/application'
import { router } from '@inertiajs/vue3'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { oauth_authorization_path } from '../../routes'
import { authenticityToken } from '../../utils/form'

const { authorization } = defineProps<{
  authorization: Authorization
}>()

const i18n = useI18n()

function deny() {
  router.delete(oauth_authorization_path({
    client_id: authorization.client_id,
    redirect_uri: authorization.redirect_uri,
    state: authorization.state,
    response_type: authorization.response_type,
    response_mode: authorization.response_mode,
    scope: authorization.scope,
    nonce: authorization.nonce,
    code_challenge: authorization.code_challenge,
    code_challenge_method: authorization.code_challenge_method,
    commit: 'deny',
  }))
}

const allowAuthorizationForm = ref<HTMLFormElement | null>(null)
function allow() {
  if (!allowAuthorizationForm.value)
    return

  // see https://github.com/doorkeeper-gem/doorkeeper-openid_connect/tree/f76ff577492f0881a746c50ca54d89e36b80941d?tab=readme-ov-file#nonces
  allowAuthorizationForm.value.action = oauth_authorization_path({
    client_id: authorization.client_id,
    redirect_uri: authorization.redirect_uri,
    state: authorization.state,
    response_type: authorization.response_type,
    response_mode: authorization.response_mode,
    scope: authorization.scope,
    nonce: authorization.nonce,
    code_challenge: authorization.code_challenge,
    code_challenge_method: authorization.code_challenge_method,
  })
  allowAuthorizationForm.value.submit()
}
</script>

<template>
  <div class="flex justify-center items-center flex-col h-100%">
    <TCard :title="i18n.t('authorize.title')" class="w-100" header-bordered>
      <div class="mb-4">
        {{ i18n.t('authorize.description', [authorization.name]) }}
      </div>
      <div class="mb-4">
        {{ i18n.t('authorize.able-to') }}
      </div>
      <ul>
        <li v-for="scope in authorization.scopes" :key="scope">
          {{ i18n.t(`scopes.${scope}`) }}
        </li>
      </ul>
      <template #footer>
        <div class="flex gap-4">
          <TButton class="w-full" theme="primary" variant="outline" @click="allow">
            {{ i18n.t('authorize.allow') }}
          </TButton>
          <TButton class="w-full" theme="danger" variant="outline" @click="deny">
            {{ i18n.t('authorize.deny') }}
          </TButton>
        </div>
      </template>
      <form ref="allowAuthorizationForm" method="post" class="hidden">
        <input type="hidden" name="authenticity_token" :value="authenticityToken()"> <!-- CSRF token -->
      </form>
    </TCard>
  </div>
</template>
