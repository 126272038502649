<script lang="ts" setup>
import { router, useForm } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'

import FlashMessages from '../../../components/FlashMessages.vue'

import { user_registration_path, user_session_path } from '../../../routes'

defineProps<{
  flash: Record<string, string[]>
}>()

const i18n = useI18n()

const form = useForm({
  email: '',
  password: '',
  password_confirmation: '',
})

function goToSignIn() {
  router.visit(user_session_path())
}

function signUp() {
  form.transform(data => ({ user: data })).post(user_registration_path())
}
</script>

<template>
  <div class="flex justify-center items-center flex-col h-100%">
    <FlashMessages :flash="flash" class="w-100" />

    <TCard class="w-100 mb-4" :title="i18n.t('users.sign-up')">
      <TForm @submit="signUp">
        <TFormItem :label="i18n.t('users.email')">
          <TInput
            v-model="form.email"
            :status="form.errors.email ? 'error' : 'default'"
            :tips="form.errors.email"
          />
        </TFormItem>
        <TFormItem :label="i18n.t('users.password')">
          <TInput
            v-model="form.password"
            type="password"
            :status="form.errors.password ? 'error' : 'default'"
            :tips="form.errors.password"
          />
        </TFormItem>
        <TFormItem :label="i18n.t('users.password')">
          <TInput
            v-model="form.password_confirmation"
            type="password"
            :status="form.errors.password_confirmation ? 'error' : 'default'"
            :tips="form.errors.password_confirmation"
          />
        </TFormItem>

        <div class="flex justify-center flex-col items-center">
          <TButton type="submit" class="mb-4">
            {{ i18n.t('users.sign-up') }}
          </TButton>

          <div class="mb-4">
            {{ i18n.t('users.already-have-account') }}

            <TLink theme="primary" @click="goToSignIn">
              {{ i18n.t('sessions.sign-in') }}
            </TLink>
          </div>
        </div>
      </TForm>
    </TCard>
  </div>
</template>
