<script setup lang="ts">
import type { Method } from '@inertiajs/core'
import type { Application } from '../../types/application'
import { useForm } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
import FlashMessages from '../FlashMessages.vue'

const { submitUrl, method, application } = defineProps<{
  submitUrl: string
  method: Method
  application: Application
  title: string
  flash: Record<string, string | string[]>
}>()

const form = useForm({
  name: application?.name ?? '',
  redirect_uri: application?.redirect_uri ?? '',
  confidential: application?.confidential ?? false,
  scopes: application?.scopes ?? ['openid'],
})

const i18n = useI18n()

function submit() {
  const scopes = form.scopes.length > 0 ? form.scopes.join(' ') : 'openid'
  form.transform((data) => {
    return {
      doorkeeper_application: {
        ...data,
        scopes,
      },
    }
  }).submit(method, submitUrl)
}

function cancel() {
  window.history.back()
}
</script>

<template>
  <div>
    <FlashMessages :flash="flash" class="mb-4" />
    <TCard :title="title">
      <TForm @submit="submit">
        <TFormItem :label="i18n.t('application.name')" :help="i18n.t('application.name-help')">
          <TInput v-model="form.name" />
        </TFormItem>
        <TFormItem :label="i18n.t('application.redirect-uri')" :help="i18n.t('application.redirect-uri-help')">
          <TTextarea v-model="form.redirect_uri" />
        </TFormItem>
        <TFormItem :label="i18n.t('application.confidential')" :help="i18n.t('application.confidential-description')">
          <TCheckbox v-model="form.confidential" />
        </TFormItem>
        <TFormItem :label="i18n.t('application.scopes')" :help="i18n.t('application.scopes-help')">
          <TTagInput v-model="form.scopes" />
        </TFormItem>

        <div class="mt-4">
          <TButton type="submit">
            {{ i18n.t('application.save') }}
          </TButton>
          <TButton class="ml-2" theme="secondary" @click="cancel">
            {{ i18n.t('application.cancel') }}
          </TButton>
        </div>
      </TForm>
    </TCard>
  </div>
</template>
