<script setup lang="tsx">
import type { TableProps } from 'tdesign-vue-next'
import type { Application } from '../../types/application'
import type { User } from '../../types/user'
import { router } from '@inertiajs/vue3'
import { Button as TButton, Icon as TIcon, Link as TLink } from 'tdesign-vue-next'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { edit_oauth_application_path, new_oauth_application_path, oauth_application_path } from '../../routes'

const { applications } = defineProps<{
  // eslint-disable-next-line vue/prop-name-casing
  current_user: User
  applications: Application[]
}>()

const i18n = useI18n()

const data = computed(() => {
  return applications.map(application => ({
    ...application,
    confidential: application.confidential ? 'Yes' : 'No',
  }))
})

function newApplication() {
  router.visit(new_oauth_application_path())
}

function edit(id: string) {
  router.visit(edit_oauth_application_path(id))
}

const showDeleteDialog = ref(false)
const pendingDelete = ref<string | undefined>(undefined)

function deleteApplication() {
  if (!pendingDelete.value)
    return

  router.delete(oauth_application_path(pendingDelete.value))
  showDeleteDialog.value = false
  pendingDelete.value = undefined
}

function showApplicationInfo(id: string) {
  router.visit(oauth_application_path(id))
}

const columns = ref<TableProps['columns']>([
  { colKey: 'name', title: i18n.t('applications.name'), cell: (_, { row }) => {
    return (
      <TLink onClick={() => showApplicationInfo(row.id)} theme="primary">
        {row.name}
      </TLink>
    )
  } },
  { colKey: 'redirect_uri', title: i18n.t('applications.redirect_uri') },
  { colKey: 'confidential', title: i18n.t('applications.confidential') },
  { colKey: 'actions', title: i18n.t('applications.actions'), cell: (_, { row }) => {
    return (
      <div class="flex gap-2">
        <TButton
          shape="circle"
          variant="text"
          theme="primary"
          onClick={() => edit(row.id)}
        >
          <TIcon name="edit" />
        </TButton>
        <TButton
          shape="circle"
          variant="text"
          theme="danger"
          onClick={() => {
            showDeleteDialog.value = true
            pendingDelete.value = row.id
          }}
        >
          <TIcon name="delete" />
        </TButton>
      </div>
    )
  } },
])
</script>

<template>
  <TCard class="m-4 applications-card" :title="i18n.t('applications.title')">
    <template #actions>
      <TButton variant="outline" @click="newApplication">
        <template #icon>
          <TIcon name="add" />
        </template>
        {{ i18n.t('applications.new') }}
      </TButton>
    </template>
    <TBaseTable
      hover
      table-layout="auto"
      row-key="index"
      :data="data"
      :columns="columns"
    />
  </TCard>
  <TDialog v-model:visible="showDeleteDialog" :header="i18n.t('applications.delete')" @confirm="deleteApplication" @cancel="showDeleteDialog = false; pendingDelete = undefined">
    <p>{{ i18n.t('applications.delete-dialog') }}</p>
  </TDialog>
</template>

<style scoped>
.applications-card :deep(.t-card__header-wrapper) {
  width: 100%;
}
</style>
