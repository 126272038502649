<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { groups } = defineProps<{
  groups: { id: string, display_name: string }[]
}>()

const i18n = useI18n()
</script>

<template>
  <TCard class="m-4" :title="i18n.t('groups.title')">
    <TList split>
      <TListItem v-for="group in groups" :key="group.id">
        <TListItemMeta :description="group.display_name" />
      </TListItem>
    </TList>
  </TCard>
</template>
