<script setup lang="ts">
import type { Application } from '../../types/application'
import { useI18n } from 'vue-i18n'
import ApplicationEditForm from '../../components/applications/ApplicationEditForm.vue'
import { oauth_applications_path } from '../../routes'

defineProps<{
  application: Application
  flash: Record<string, string | string[]>
}>()

const i18n = useI18n()
</script>

<template>
  <ApplicationEditForm
    class="m-4"
    :title="i18n.t('application.new')"
    :submit-url="oauth_applications_path()"
    :application="application"
    :flash="flash"
    method="post"
  />
</template>
