<script lang="ts" setup>
import { useForm } from '@inertiajs/vue3'
import { useBrowserLocation } from '@vueuse/core'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import FlashMessages from '../../../components/FlashMessages.vue'
import {
  new_user_confirmation_path,
  new_user_password_path,
  new_user_registration_path,
  new_user_session_path,
  new_user_unlock_path,
  user_developer_omniauth_authorize_path,
  user_internal_aad_omniauth_authorize_path,
} from '../../../routes'
import { authenticityToken } from '../../../utils/form'

const { flash } = defineProps<{
  flash: Record<string, string[]>
}>()

const i18n = useI18n()

const form = useForm({
  email: '',
  password: '',
})

const browserLocation = useBrowserLocation()

function signIn() {
  form.transform(data => ({ user: data })).post(new_user_session_path())
}

const omniAuthForm = ref<HTMLFormElement | null>(null)

function signInWithDeveloper() {
  if (!omniAuthForm.value)
    return
  omniAuthForm.value.action = user_developer_omniauth_authorize_path()
  omniAuthForm.value.submit()
}

function signInWithInternalAad() {
  if (!omniAuthForm.value)
    return
  omniAuthForm.value.action = user_internal_aad_omniauth_authorize_path()
  omniAuthForm.value.submit()
}

const isProduction = import.meta.env.PROD
</script>

<template>
  <div class="flex justify-center items-center flex-col h-100%">
    <FlashMessages :flash="flash" class="w-100" />

    <TCard class="w-100 mb-4" :title="i18n.t('sessions.sign-in')">
      <TForm @submit="signIn">
        <TFormItem :label="i18n.t('sessions.email')">
          <TInput v-model="form.email" />
        </TFormItem>
        <TFormItem :label="i18n.t('sessions.password')" class="mb-8">
          <TInput v-model="form.password" type="password" />
        </TFormItem>

        <div class="flex justify-center flex-col items-center">
          <TButton type="submit" class="mb-4">
            {{ i18n.t('sessions.sign-in') }}
          </TButton>

          <div>
            {{ i18n.t('sessions.no-account') }}

            <TLink theme="primary" :href="new_user_registration_path()" target="_blank">
              {{ i18n.t('users.sign-up') }}
            </TLink>
          </div>

          <TLink theme="primary" :href="new_user_password_path()" target="_blank">
            {{ i18n.t('send-instructions.forgot-password.title') }}
          </TLink>

          <TLink theme="primary" :href="new_user_confirmation_path()" target="_blank">
            {{ i18n.t('send-instructions.confirmation.title') }}
          </TLink>

          <TLink theme="primary" :href="new_user_unlock_path()" target="_blank">
            {{ i18n.t('send-instructions.unlock.title') }}
          </TLink>

          <TLink v-if="browserLocation.hash === '#developer' && !isProduction" theme="primary" @click="signInWithDeveloper">
            {{ i18n.t('sessions.sign-in-with') }} Developer
          </TLink>

          <TLink v-if="browserLocation.hash === '#internal_aad'" theme="primary" @click="signInWithInternalAad">
            {{ i18n.t('sessions.sign-in-with') }} InternalAad
          </TLink>

          <form ref="omniAuthForm" method="post" class="hidden">
            <input type="hidden" name="authenticity_token" :value="authenticityToken()">
          </form>
        </div>
      </TForm>
    </TCard>
  </div>
</template>
