<script setup lang="ts">
import type { Profile } from '../../types/user'
import { router } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
import defaultAvatar from '../../assets/images/default-avatar.png'
import { edit_profile_path } from '../../routes'

const { profiles } = defineProps<{
  profiles: Profile[]
}>()

const i18n = useI18n()

function editProfile(profile: Profile) {
  router.visit(edit_profile_path(profile.id))
}
</script>

<template>
  <TCard class="m-4" :title="i18n.t('profiles.title')">
    <TList split>
      <TListItem v-for="profile in profiles" :key="profile.id">
        <TListItemMeta :image="profile.avatar ?? defaultAvatar" :title="profile.display_name" :description="profile.email" />
        <template #action>
          <TButton variant="text" shape="circle" theme="primary" @click="editProfile(profile)">
            <TIcon name="edit" />
          </TButton>
        </template>
      </TListItem>
    </TList>
  </TCard>
</template>
