<script setup lang="ts">
import { useForm } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  title: string
  description: string
  path: string
}>()

const i18n = useI18n()

const form = useForm({
  email: '',
})

function sendInstructions() {
  form.transform(data => ({ user: data })).post(props.path)
}
</script>

<template>
  <TCard :title="title" class="w-100">
    <div class="mb-4">
      {{ description }}
    </div>
    <TForm @submit="sendInstructions">
      <TInput v-model="form.email" class="mb-4" />
      <TButton type="submit">
        {{ i18n.t('send-instructions.button-text') }}
      </TButton>
    </TForm>
  </TCard>
</template>
