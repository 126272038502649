<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import SendInstructionsForm from '../../../components/SendInstructionsForm.vue'
import { user_unlock_path } from '../../../routes'

const i18n = useI18n()
</script>

<template>
  <div class="flex justify-center items-center flex-col h-100%">
    <SendInstructionsForm
      :title="i18n.t('send-instructions.unlock.title')"
      :description="i18n.t('send-instructions.unlock.description')"
      :path="user_unlock_path()"
    />
  </div>
</template>
